import { mightFail } from 'might-fail'
import { useDataStore } from '../machine/use-data-store'

const IS_ONLINE_API_URL = import.meta.env.VITE_IS_ONLINE_API_URL
if (!IS_ONLINE_API_URL) throw new Error('IS_ONLINE_API_URL is not defined')

// navigator.online is not reliable:
// it returns true if connected to a local area network (LAN), even without internet access.
// We need to check if we are online by making a request to a server

export const isOnline = async () => {
  const setIsOnline = useDataStore.getState().setIsOnline
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 2000)

  const { error: networkError } = await mightFail(
    fetch(IS_ONLINE_API_URL, {
      method: 'HEAD', // We only need headers, not the full response
      signal: controller.signal
    })
  )
  clearTimeout(timeoutId)

  if (networkError) {
    setIsOnline(false)
    return false
  }

  setIsOnline(true)
  return true
}
