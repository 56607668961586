import { Either, mightFail } from 'might-fail'
import { useDataStore } from '../machine/use-data-store'
import { useDataStoreLocalStorage } from '../machine/use-data-store-local-storage'

interface AuthFetchOptions extends RequestInit {
  timeout?: number // timeout in milliseconds
}

///////////////////////////////
// This function is used to make authenticated fetch requests with a timeout
// It will automatically refresh the access token if it's expired
// It will also log the user out if the refresh token is invalid
///////////////////////////////

export const fetchWithAuth = async (
  resource: string,
  options: AuthFetchOptions = {}
): Promise<Either<Response>> => {
  const { timeout = 5000, ...fetchOptions } = options
  const auth = useDataStoreLocalStorage.getState().auth
  if (!auth) return { error: new Error('Not authenticated'), result: undefined }

  const logout = useDataStoreLocalStorage.getState().logout

  const executeRequest = async (token: string): Promise<Either<Response>> => {
    const { error: networkError, result: response } = await mightFail(
      fetch(resource, {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        signal: AbortSignal.timeout(timeout)
      })
    )

    if (networkError) {
      return { error: new Error('Network error: Unable to reach the server.'), result: undefined }
    }

    if (response.status === 401) {
      await logout()

      return {
        result: undefined,
        error: new Error(`Server responded with status: ${response.status}`)
      }
    }

    return { result: response, error: undefined }
  }

  const accessToken = auth.accessToken
  if (!accessToken) {
    await logout()
    return {
      result: undefined,
      error: new Error('No access token available')
    }
  }

  // Start the request process
  const { result, error } = await executeRequest(accessToken)
  if (error) return { result: undefined, error }

  return { result, error: undefined }
}
