import { createId } from '@paralleldrive/cuid2'
import {
  LoginInput,
  LoginOutput,
  LoginOutputSchema,
  RefreshTokenInput,
  RefreshTokenOutput,
  RefreshTokenOutputSchema
} from '@uv/machine'
import { mightFail } from 'might-fail'

const CLOUD_API_URL = import.meta.env.VITE_CLOUD_API_URL
if (!CLOUD_API_URL) throw new Error('CLOUD_API_URL is not defined')

export const initializeDeviceId = (): string => {
  let deviceId = localStorage.getItem('deviceId')
  if (!deviceId) {
    deviceId = createId()
    localStorage.setItem('deviceId', deviceId)
  }
  return deviceId
}

export const login = async (
  email: string,
  password: string,
  deviceId: string
): Promise<LoginOutput> => {
  const { error: networkError, result: response } = await mightFail(
    fetch(`${CLOUD_API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        deviceId
      } satisfies LoginInput)
    })
  )

  if (networkError) {
    throw new Error('Network error during login')
  }

  if (!response.ok) {
    console.error('Error login response from server', response)
    throw new Error(`Login failed: ${response.statusText}`)
  }

  const { error: convertToJSONError, result } = await mightFail(response.json())

  if (convertToJSONError) {
    console.error('Invalid JSON', convertToJSONError.message)
    throw new Error('Invalid response from server')
  }

  const parsedResult = LoginOutputSchema.safeParse(result)
  if (!parsedResult.success) {
    console.error(parsedResult.error.message)
    throw new Error('Invalid login response schema')
  }

  return parsedResult.data
}

export const refreshToken = async (
  refreshToken: string,
  deviceId: string
): Promise<RefreshTokenOutput> => {
  const { error: networkError, result: response } = await mightFail(
    fetch(`${CLOUD_API_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken, deviceId } satisfies RefreshTokenInput)
    })
  )

  if (networkError) {
    throw new Error('Network error during token refresh')
  }

  if (!response.ok) {
    console.error('Error refreshtoken response from server', response)
    throw new Error(`Token refresh failed: ${response.statusText}`)
  }

  const { error: convertToJSONError, result } = await mightFail(response.json())

  if (convertToJSONError) {
    console.error('Invalid JSON', convertToJSONError.message)
    throw new Error('Invalid response from server')
  }

  const parsedResult = RefreshTokenOutputSchema.safeParse(result)
  if (!parsedResult.success) {
    console.error(parsedResult.error.message)
    throw new Error('Invalid refresh token response schema')
  }

  return parsedResult.data
}

export const logout = async (accessToken: string): Promise<void> => {
  const { error: networkError, result: response } = await mightFail(
    fetch(`${CLOUD_API_URL}/auth/logout`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
  )

  if (networkError) {
    throw new Error('Network error during logout')
  }

  if (!response.ok) {
    console.error('Error logout response from server', response)
    throw new Error(`Logout failed: ${response.statusText}`)
  }
}
