import { Label } from '@uv/ui/components/ui/label'
import { DashboardCard, DashboardCardTitle } from '../../ui/card-dashboard'
import { Button } from '@ui/components/ui/button'
import { Progress } from '@ui/components/ui/progress'
import { CameraIcon, VideoCameraIcon } from '@heroicons/react/24/solid'
import { InstallationButton } from '@/components/ui/installation-button'
import { cn } from '@ui/utils'
import { TopBar } from '../shared/top-bar'
import { InvertInstallationUiButton } from '../shared/invert-installation-ui-button'
import { Alert } from '../shared/alert'
import { InstallationToggle } from '@/components/ui/installation-toggle'
import { SystemOverrideLayout } from '../shared/settings/service-mode-layout'
import { CheckIcon, LoaderCircleIcon } from 'lucide-react'
import { convertPressure, getPressureSymbol } from '@/lib/units'
import { VideoWebRTC } from '../shared/video-webrtc'
import { useMachineVideo } from '@/lib/machine/use-machine-video'
import { StageSwitcher } from '../shared/stage-switcher'
import { MIN_AIR_PRESSURE_BAR, MAX_AIR_PRESSURE_BAR } from '@/lib/constants'
import { scaleClamp } from '@/lib/utils'
import { LampControls } from '../shared/lamp-controls'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useTranslation } from 'react-i18next'

const VIDEO_URL = import.meta.env.VITE_VIDEO_URL
if (!VIDEO_URL) throw new Error('VIDEO_URL is not defined')

export const InstallationStage2Inversion = () => {
  const { t } = useTranslation()
  const refreshData = useDataStore(s => s.refreshData)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const mockMode = useDataStore(s => s.mockMode)
  const AIR_PRESSURE = refreshData?.AIR_PRESSURE ?? 0

  const {
    reconnect,
    remoteStream,
    isVideoRecording,
    isProcessingVideoStopRecording,
    isProcessingSnapshot,
    sendStartRecordingMessage,
    sendStopRecordingMessage,
    sendTakeScreenshotMessage
  } = useMachineVideo()
  const isVideoActive = Boolean(remoteStream?.active)
  const settings = useDataStoreLocalStorage(s => s.settings)

  const pressureValue = convertPressure({
    value: AIR_PRESSURE,
    from: 'bar',
    to: settings.unitPressure
  })
  const pressureValueFormatted = isNaN(pressureValue)
    ? '-'
    : pressureValue === 0
      ? '0'
      : pressureValue.toFixed(2)

  return (
    <div
      className={cn(
        `grid grid-rows-[30px,1fr] gap-8 h-screen w-screen p-8 pt-6`,
        settings.flipUI ? 'grid-cols-[380px,1fr]' : 'grid-cols-[1fr,380px]'
      )}
    >
      <TopBar />
      <Alert />
      <SystemOverrideLayout />

      <div className={cn('relative w-full flex items-start', settings.flipUI && 'order-3')}>
        {mockMode ? (
          <video
            className="flex-grow rounded-2xl w-auto max-h-full"
            muted
            autoPlay
            loop
            playsInline
          >
            <source src="/video-inversion.mp4" type="video/mp4" />
          </video>
        ) : (
          <VideoWebRTC
            reconnect={reconnect}
            remoteStream={remoteStream}
            className="flex-grow rounded-2xl w-auto max-h-full"
          />
        )}

        <DashboardCard className="absolute top-4 right-4 bg-zinc-800/80 p-8 rounded-3xl select-none">
          <div className="flex flex-col gap-4">
            <DashboardCardTitle className="mb-0">
              {t('installation.inversion.air-pressure')}
            </DashboardCardTitle>

            <div className="flex gap-2 items-baseline">
              <span className="text-accent text-5xl font-bold">{pressureValueFormatted}</span>
              <span className="text-accent text-2xl font-bold uppercase">
                {getPressureSymbol(settings.unitPressure)}
              </span>
            </div>
          </div>
          <Progress
            value={scaleClamp(
              pressureValue,
              [MIN_AIR_PRESSURE_BAR, MAX_AIR_PRESSURE_BAR],
              [0, 100]
            )}
            className="mt-6 bg-zinc-900"
          />
        </DashboardCard>
        <InvertInstallationUiButton />
      </div>

      <div className="flex flex-col min-w-[380px] max-w-[380px] mx-auto gap-6">
        <StageSwitcher />

        <div className="flex gap-4">
          <InstallationToggle
            aria-label="Toggle Video Recording"
            pressed={isVideoRecording}
            onPressedChange={() => {
              isVideoRecording ? sendStopRecordingMessage() : sendStartRecordingMessage()
            }}
            className="flex flex-col items-center gap-4 p-6 rounded-2xl aspect-square w-1/2 h-28 text-foreground bg-zinc-700 disabled:opacity-100 select-none"
            disabled={!isVideoActive || isProcessingVideoStopRecording || isProcessingSnapshot}
          >
            {isProcessingVideoStopRecording ? (
              <LoaderCircleIcon className="w-8 h-8 animate-spin" />
            ) : (
              <VideoCameraIcon className="w-8 h-8" />
            )}

            <Label className="text-center text-md">
              {t('installation.inversion.record-button')}
            </Label>
          </InstallationToggle>

          <InstallationButton
            aria-label="Take Snapshot"
            onClick={async () => {
              await sendTakeScreenshotMessage(sendMachineAction)
            }}
            disabled={!isVideoActive || isProcessingVideoStopRecording || isProcessingSnapshot}
            className="flex flex-col items-center gap-4 p-6 rounded-2xl aspect-square w-1/2 h-28 text-foreground bg-zinc-700 disabled:opacity-100 select-none"
          >
            {isProcessingSnapshot ? (
              <LoaderCircleIcon className="w-8 h-8 animate-spin" />
            ) : (
              <CameraIcon className="w-8 h-8" />
            )}
            <Label className="text-center text-md">
              {t('installation.inversion.snapshot-button')}
            </Label>
          </InstallationButton>
        </div>

        <LampControls />

        <Button
          className="w-full h-auto text-xl py-10 flex justify-center items-center gap-2 text-foreground bg-zinc-700 rounded-3xl hover:bg-zinc-700 select-none"
          onClick={() => sendMachineAction({ in: 'curing_stage' })}
        >
          <CheckIcon strokeWidth={3} className="w-8" />
          <span>{t('installation.inversion.complete-inversion-button')}</span>
        </Button>
      </div>
    </div>
  )
}
