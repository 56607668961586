import { useDataStore } from '@/lib/machine/use-data-store'
import { cn } from '@ui/utils'
import { Link } from 'react-router-dom'
import { ShowDebugDataButton } from './show-debug-data-button'
import { useEffect, useRef } from 'react'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { resetLocalData } from '@/lib/reset/reset-local-data'

const DevBarWithGuard = () => {
  const auth = useDataStoreLocalStorage(s => s.auth)
  const logout = useDataStoreLocalStorage(s => s.logout)
  const mockMode = useDataStore(s => s.mockMode)
  const setMockMode = useDataStore(s => s.setMockMode)
  const machineId = useDataStore(s => s.machineId)
  const isConnected = useDataStore(s => s.isConnected)

  // This is a hack to bypass the alert dialog focus trap, so the user can interact with the controls in mock mode
  // We wait 100ms to make sure the alert dialog is open before enabling pointer events
  const observerRef = useRef<MutationObserver | null>(null)
  const timeoutRef = useRef<number | null>(null)
  const rafRef = useRef<number | null>(null)

  useEffect(() => {
    const targetNode = document.body
    const config: MutationObserverInit = { childList: true, subtree: true }

    const enablePointerEvents = () => {
      const focusGuard = document.querySelector('span[data-radix-focus-guard]')
      if (focusGuard) {
        // Clear any existing timeout
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current)
        }

        // Set pointer-events to auto
        document.body.style.pointerEvents = 'auto'

        // Set a timeout to revert pointer-events after a short delay
        timeoutRef.current = window.setTimeout(() => {
          document.body.style.pointerEvents = ''
          timeoutRef.current = null
        }, 100) // Adjust this delay as needed

        // Cancel the animation frame if it's running
        if (rafRef.current !== null) {
          cancelAnimationFrame(rafRef.current)
          rafRef.current = null
        }
      }
    }

    const callback: MutationCallback = () => {
      enablePointerEvents()
    }

    observerRef.current = new MutationObserver(callback)
    observerRef.current.observe(targetNode, config)

    const checkVisibilityAndEnablePointerEvents = () => {
      if (!document.hidden) {
        enablePointerEvents()
        rafRef.current = requestAnimationFrame(checkVisibilityAndEnablePointerEvents)
      } else if (rafRef.current !== null) {
        cancelAnimationFrame(rafRef.current)
        rafRef.current = null
      }
    }

    // Initial check
    checkVisibilityAndEnablePointerEvents()

    // Add visibility change event listener
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkVisibilityAndEnablePointerEvents()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      if (observerRef.current) observerRef.current.disconnect()
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current)
      if (rafRef.current !== null) cancelAnimationFrame(rafRef.current)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const handleLogout = async () => {
    await logout()
    await resetLocalData()
  }

  return (
    <div className="fixed bottom-0 left-0 w-full flex justify-between z-[9999999999]">
      <ShowDebugDataButton />

      <div className="flex gap-2">
        <Link to="https://192.168.250.2/debug" className="font-semibold uppercase text-blue-500">
          GO TO DEBUG UI
        </Link>
        -
        <div
          className={cn(
            auth.user?.id ? 'text-green-500' : 'text-red-500',
            'font-semibold uppercase flex gap-2'
          )}
        >
          {auth.user?.id ? (
            <button onClick={handleLogout}>{auth.user.email}</button>
          ) : (
            <Link to="/?login">Logged Out</Link>
          )}
        </div>
        -
        {!mockMode && (
          <>
            <div className="flex flexc-col items-center gap-4 text-center">
              <span
                className={cn(isConnected ? 'text-green-500' : 'text-red-500', 'font-semibold')}
              >
                {isConnected ? `CONNECTED ${machineId}` : 'DISCONNECTED'}
              </span>
            </div>
            -
          </>
        )}
        <button
          className={cn(
            mockMode ? 'text-yellow-500' : 'text-foreground',
            'font-semibold uppercase flex gap-2'
          )}
          onClick={() => setMockMode(!mockMode)}
        >
          Mock mode {mockMode ? 'on' : 'off'}
        </button>
      </div>
    </div>
  )
}

export const DevBar = () => {
  const devMode = useDataStore(s => s.devMode)
  if (!devMode) return null

  return <DevBarWithGuard />
}
