import { dbGetAllVideos } from '@/db/actions'
import { toast } from '@ui/components/ui/sonner'
import { cloudSyncVideos } from './cloud-sync-videos'
import { handleFileSyncActions } from '../machine/sync/handle-file-sync-actions'
import { useDataStoreLocalStorage } from '../machine/use-data-store-local-storage'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useCloudSyncVideos = () => {
  const auth = useDataStoreLocalStorage(s => s.auth)

  const syncVideos = async () => {
    if (!auth.isAuthenticated) return

    const localVideos = await dbGetAllVideos()

    const videosWithActions = await cloudSyncVideos(
      localVideos.map(i => {
        return {
          fileName: i.fileName,
          duration: i.duration,
          hasFile: Boolean(i.hasFile)
        }
      })
    )
    if (!videosWithActions) return

    await handleFileSyncActions({
      type: 'video',
      filesWithSyncActions: videosWithActions
    })

    toast.success('Synced Videos with Cloud', {
      position: 'top-center',
      duration: 2000
    })
  }

  return syncVideos
}
