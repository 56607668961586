import { isOnline } from '@/lib/utils/is-online'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@ui/components/ui/dialog'
import { useState, useEffect } from 'react'
import { Login } from '../auth/login'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'

export const LoginDialogForced = () => {
  const auth = useDataStoreLocalStorage(s => s.auth)
  const [showLogin, setShowLogin] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    const checkShouldShowAuth = async () => {
      if (auth.isAuthenticated || dismissed) return setShowLogin(false)
      if (await isOnline()) return setShowLogin(true)
    }

    checkShouldShowAuth()
  }, [auth.isAuthenticated, dismissed])

  if (!showLogin) return null

  return (
    <Dialog
      open={showLogin}
      onOpenChange={v => {
        if (!v) setDismissed(true)
      }}
    >
      <DialogContent className="max-h-1/2 p-12  border-none">
        <DialogTitle className="hidden">Login</DialogTitle>
        <DialogDescription className="hidden">Login</DialogDescription>
        <Login />
      </DialogContent>
    </Dialog>
  )
}
