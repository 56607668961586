import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Input } from '../installation/stages/1-setup/setup-form-components'
import { useTranslation } from 'react-i18next'
import { Button } from '@ui/components/ui/button'
import { Loader2Icon } from 'lucide-react'

export const Login = () => {
  const navigate = useNavigate()
  const login = useDataStoreLocalStorage(s => s.login)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError('')
    if (!email.trim() || !password.trim()) return
    setIsLoading(true)

    try {
      await login(email, password)
      navigate('/')
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Login error:', error)
      setError(t('login.error-message'))
    }
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="mt-6 text-center text-3xl font-bold">{t('login.title')}</h2>
      </div>
      <form className="mt-8 space-y-8" onSubmit={handleSubmit}>
        <div className="space-y-6">
          <input type="hidden" name="remember" value="true" />

          <div className="flex flex-col gap-2">
            <Input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={t('login.email')}
            />
          </div>

          <div className="flex flex-col gap-2">
            <Input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder={t('login.password')}
            />
          </div>

          {error && <div className="text-red-500 font-bold text-center">{error}</div>}
        </div>

        <Button
          type="submit"
          className="w-full h-auto text-xl py-6 flex justify-center items-center gap-2 text-foreground bg-accent rounded-2xl hover:bg-acccent select-none disabled:opacity-100"
          disabled={isLoading}
        >
          {isLoading && <Loader2Icon className="h-5 w-5 animate-spin" />}

          <span>{t('login.button')}</span>
        </Button>
      </form>
    </div>
  )
}
