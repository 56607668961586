import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@ui/components/ui/dialog'
import { useSearchParams } from 'react-router-dom'
import { Login } from '../auth/login'

export const LoginDialog = () => {
  const { isAuthenticated } = useDataStoreLocalStorage.getState().auth
  const [searchParams, setSearchParams] = useSearchParams()
  const isLogin = searchParams.has('login')

  const setLogin = () => {
    const isLogin = searchParams.has('login')
    if (isLogin) {
      searchParams.delete('login')
      setSearchParams(searchParams)
    } else {
      setSearchParams({ login: 'true' })
    }
  }

  if (isAuthenticated) return null

  return (
    <Dialog open={isLogin} onOpenChange={() => setLogin()}>
      <DialogContent className="max-h-1/2 p-12 border-none">
        <DialogTitle className="sr-only">Login</DialogTitle>
        <DialogDescription className="sr-only">Login</DialogDescription>
        <Login />
      </DialogContent>
    </Dialog>
  )
}
