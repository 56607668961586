import { calculateWatchdogNextValue } from '@/lib/installation/calculate-watchdog-next-value'
import {
  MachineInMessage,
  InstallationFileMeta,
  RefreshData,
  speedRecipeParametersToRpmList,
  powerRecipeParametersToUvCycle,
  getDefaultAlarmOverrides,
  getDefaultMachineConfiguration,
  rpmListToRecipeParameters
} from '@uv/machine'
import { getDefaultMockedMachineRefreshData } from '@/lib/mock/mock'
import { machineWebSocket } from '@/lib/websocket/machine-websocket/machine-websocket'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL is not defined')

type WithoutId<T> = T extends { id: string } ? Omit<T, 'id'> : T
type MachineInMessageExcludingHello = Exclude<MachineInMessage, { in: 'hello' }>
type MachineInMessageWithoutId = WithoutId<MachineInMessageExcludingHello>

type WithoutWatchdog<T> = T extends { watchdog: number } ? Omit<T, 'watchdog'> : T
type MachineActionSendMessage = WithoutWatchdog<MachineInMessageWithoutId>

export type MachineAction =
  | MachineActionSendMessage
  | {
      in: 'setup_filemeta'
      fileMeta: InstallationFileMeta
    }
  | {
      in: 'inversion_stage'
    }
  | {
      in: 'curing_stage'
    }
  | {
      in: 'summary_stage'
    }
  | {
      in: 'finish_installation'
    }

const setActiveInstallation = useDataStoreLocalStorage.getState().setActiveInstallation
const setActiveInstallationStage = useDataStoreLocalStorage.getState().setActiveInstallationStage
const setActiveInstallationFinishedAt =
  useDataStoreLocalStorage.getState().setActiveInstallationFinishedAt
const addActiveInstallationToLocalRecords =
  useDataStoreLocalStorage.getState().addActiveInstallationToLocalRecords

////////////////////////////////////////
// MESSAGES - ACTIONS
////////////////////////////////////////

export const handleSendMachineAction = (params: MachineAction) => {
  const get = useDataStore.getState
  const set = useDataStore.setState

  const mockMode = get().mockMode

  const sendMessage = (message: MachineInMessageWithoutId) => {
    const machineId = get().machineId
    const mockMode = get().mockMode
    if (
      !machineWebSocket ||
      machineWebSocket.readyState !== WebSocket.OPEN ||
      !machineId ||
      mockMode
    )
      return
    machineWebSocket.send(
      JSON.stringify({
        ...message,
        id: machineId
      } satisfies MachineInMessageExcludingHello)
    )
  }

  // Similar API to setState
  // It accepts a function that exposes previous value
  const setPartialMockedRefreshData = (
    v: Partial<RefreshData> | ((prevRefreshData: RefreshData | null) => Partial<RefreshData>)
  ) => {
    set(prev => {
      const prevRefreshData = prev.refreshData ?? getDefaultMockedMachineRefreshData()
      const nextValues = typeof v === 'function' ? v(prev.refreshData) : v

      return {
        refreshData: {
          ...prevRefreshData,
          ...nextValues
        }
      }
    })
  }

  switch (params.in) {
    ///////////////////////////////
    // Installation Stage Actions
    ///////////////////////////////
    case 'setup_filemeta': {
      setActiveInstallation({
        stage: 'setup',
        fileMeta: params.fileMeta,
        samples: []
      })

      // Configuration
      const recipe = params.fileMeta.installationInfo.recipe
      const rpmList = speedRecipeParametersToRpmList(recipe)
      const uvCycle = powerRecipeParametersToUvCycle(recipe)

      sendMessage({
        in: 'rpm_configure',
        list: rpmList
      })

      sendMessage({
        in: 'uv_configure',
        cycle: uvCycle
      })

      sendMessage({
        in: 'overrides_configure',
        overrides: getDefaultAlarmOverrides()
      })

      sendMessage({
        in: 'alarms_reset'
      })

      sendMessage({
        in: 'rod_reset',
        distance: 0,
        slip: 0
      })

      // Start recording
      sendMessage({
        in: 'file_start',
        meta: params.fileMeta
      })

      return
    }

    case 'inversion_stage': {
      get().setDismissedAlarms([])
      sendMessage({
        in: 'alarms_reset'
      })

      if (mockMode) {
        setPartialMockedRefreshData({
          RECORDING: true,
          ALARMS: [false, false, false, false, false, false, false, false]
        })
      }

      sendMessage({
        in: 'localstore_set',
        meta: { installationStage: 'inversion' }
      })

      setActiveInstallationStage('inversion')
      return
    }

    case 'curing_stage': {
      get().setDismissedAlarms([])
      sendMessage({
        in: 'alarms_reset'
      })

      sendMessage({
        in: 'localstore_set',
        meta: { installationStage: 'curing' }
      })

      setActiveInstallationStage('curing')
      return
    }

    case 'summary_stage': {
      get().setDismissedAlarms([])

      // Always stop UV light and puller on completing installation
      sendMessage({
        in: 'uv_set',
        factor: 0
      })

      sendMessage({
        in: 'rpm_set',
        factor: 0
      })

      // Finish recording file
      sendMessage({
        in: 'file_stop'
      })

      if (mockMode) {
        setPartialMockedRefreshData({
          RECORDING: false,
          ALARMS: [false, false, false, false, false, false, false, false]
        })
      }

      const finishedAt = new Date().toISOString()
      addActiveInstallationToLocalRecords(finishedAt)
      setActiveInstallationFinishedAt(finishedAt)

      sendMessage({
        in: 'localstore_set',
        meta: { installationStage: 'summary' }
      })

      // Sync files. Which will include current installation
      console.log('Sending sync files action')
      get().sendMachineSyncFilesAction({ in: 'file_list' })

      setActiveInstallationStage('summary')
      return
    }

    case 'finish_installation': {
      get().setDismissedAlarms([])
      sendMessage({
        in: 'localstore_set',
        meta: { installationStage: 'setup' }
      })

      // Reset configuration

      sendMessage({
        in: 'rpm_configure',
        list: getDefaultMachineConfiguration().rpm_list
      })

      sendMessage({
        in: 'uv_configure',
        cycle: getDefaultMachineConfiguration().uv_cycle
      })

      sendMessage({
        in: 'overrides_configure',
        overrides: getDefaultAlarmOverrides()
      })

      setActiveInstallation(null)
      return
    }

    ///////////////////////////////
    // Machine actions
    ///////////////////////////////

    case 'rpm_configure': {
      if (mockMode) {
        setPartialMockedRefreshData({ RPM: params.list[0] })
      }

      return sendMessage({
        in: 'rpm_configure',
        list: params.list
      })
    }

    case 'file_start': {
      if (mockMode) setPartialMockedRefreshData({ RECORDING: true })

      return sendMessage({
        in: 'file_start',
        meta: params.meta
      })
    }

    case 'file_stop': {
      if (mockMode) setPartialMockedRefreshData({ RECORDING: false })

      return sendMessage({
        in: 'file_stop'
      })
    }

    case 'file_write': {
      return sendMessage({
        in: 'file_write',
        file: params.file,
        payload: params.payload
      })
    }

    case 'file_meta': {
      return sendMessage({
        in: 'file_meta',
        file: params.file
      })
    }

    case 'file_list': {
      return sendMessage({
        in: 'file_list'
      })
    }

    case 'file_event': {
      return sendMessage({
        in: 'file_event',
        meta: params.meta
      })
    }

    case 'localstore_get': {
      return sendMessage({
        in: 'localstore_get'
      })
    }

    case 'localstore_set': {
      return sendMessage({
        in: 'localstore_set',
        meta: params.meta
      })
    }

    case 'datetime_set': {
      return sendMessage({
        in: 'datetime_set',
        ts: params.ts
      })
    }

    case 'configuration_get': {
      return sendMessage({
        in: 'configuration_get'
      })
    }

    case 'refresh': {
      return sendMessage({
        in: 'refresh',
        watchdog: calculateWatchdogNextValue(get().refreshData?.WATCHDOG ?? 0)
      })
    }

    case 'rpm_set': {
      if (mockMode) {
        const rpmList =
          get().machineConfiguration?.rpm_list || getDefaultMachineConfiguration().rpm_list
        const { maxSpeed } = rpmListToRecipeParameters(rpmList)
        setPartialMockedRefreshData({
          RPM: (maxSpeed * params.factor) / 100,
          RPM_FACTORS: [params.factor, 0, 100]
        })
      }

      return sendMessage({
        in: 'rpm_set',
        factor: params.factor
      })
    }

    case 'rod_service_toggle': {
      if (mockMode) {
        setPartialMockedRefreshData(prevRefreshData => ({
          ROD_SERVICE: !prevRefreshData?.ROD_SERVICE
        }))
      }

      return sendMessage({
        in: 'rod_service_toggle'
      })
    }

    case 'lamp_set': {
      if (mockMode) setPartialMockedRefreshData({ LAMP: params.factor })

      return sendMessage({
        in: 'lamp_set',
        factor: params.factor
      })
    }

    case 'uv_set': {
      if (mockMode) {
        if (params.factor === 100) {
          setTimeout(() => {
            return setPartialMockedRefreshData({
              UV_TEMP: [60, 60, 60, 60, 60]
            })
          }, 1500)
        }
        return setPartialMockedRefreshData({
          UV_TEMP: [30, 30, 30, 30, 30],
          UV_FACTORS: [params.factor, 100, 100] // TODO: calculate the other two
        })
      }

      return sendMessage({
        in: 'uv_set',
        factor: params.factor
      })
    }

    case 'alarms_reset': {
      if (mockMode) {
        setPartialMockedRefreshData({
          ALARMS: [false, false, false, false, false, false, false, false]
        })
      }

      return sendMessage({
        in: 'alarms_reset'
      })
    }

    case 'overrides_configure': {
      if (mockMode) {
        set(s => {
          return {
            machineConfiguration: {
              ...s.machineConfiguration,
              overrides: params.overrides
            }
          }
        })
      }

      return sendMessage({
        in: 'overrides_configure',
        overrides: params.overrides
      })
    }

    case 'tablet_control': {
      return sendMessage({
        in: 'tablet_control',
        command: params.command
      })
    }

    default: {
      return
    }
  }
}
