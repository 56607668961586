import { useTranslation } from 'react-i18next'
import { cn } from '@ui/utils'
import { Card, CardHeader, CardTitle, CardContent } from '@ui/components/ui/card'
import { Button } from '@ui/components/ui/button'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetLocalData } from '@/lib/reset/reset-local-data'
import { CircleAlertIcon } from 'lucide-react'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'

export const SettingsAccount = () => {
  const logout = useDataStoreLocalStorage(s => s.logout)
  const auth = useDataStoreLocalStorage(s => s.auth)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isInstallation = pathname.includes('/installation')
  const isHome = pathname === '/'

  const handleSignOut = async () => {
    await logout()
    await resetLocalData()
  }

  const handleSignIn = () => {
    // Homepage will show the login dialog if the user is not logged in.
    // If we are already in the homepage, we use /?login to display the dialog
    navigate(isHome ? '/?login' : '/')
  }

  return (
    <section>
      <Card className={cn('p-2 bg-zinc-900/70 rounded-2xl select-none')}>
        <CardHeader className="pb-0">
          <CardTitle className="flex items-center gap-4">{t('settings.account-title')}</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col">
          <div className="flex gap-2 items-center justify-between">
            {auth?.user?.email ? (
              <>
                <div className="flex flex-col justify-center">
                  {auth.user.id && <span>{auth.user.name}</span>}
                  {auth.user.email && <span>{auth.user.email}</span>}
                </div>

                <Button onClick={handleSignOut} disabled={isInstallation}>
                  {t('settings.account-logout-button')}
                </Button>
              </>
            ) : (
              <Button onClick={handleSignIn} disabled={isInstallation}>
                {t('settings.account-login-button')}
              </Button>
            )}
          </div>
          {isInstallation && (
            <div className="flex items-center gap-2 mt-6 text-orange-400">
              <CircleAlertIcon className="w-4 h-4" />
              {t('settings.account-during-installation-description')}
            </div>
          )}
        </CardContent>
      </Card>
    </section>
  )
}
