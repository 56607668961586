import { SyncServerStateInput, SyncServerStateOutputSchema } from '@uv/machine'
import { useDataStoreLocalStorage } from '../machine/use-data-store-local-storage'
import { fetchWithAuth } from '../auth/fetch-with-auth'
import { toast } from '@ui/components/ui/sonner'
import { useTranslation } from 'react-i18next'
import { mightFail } from 'might-fail'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useCloudSyncState = () => {
  const { t } = useTranslation()
  const auth = useDataStoreLocalStorage(s => s.auth)
  const machinesSeen = useDataStoreLocalStorage(s => s.machinesSeen)
  const setCloudLastSync = useDataStoreLocalStorage(s => s.setCloudLastSync)
  const setInstallationOptions = useDataStoreLocalStorage(s => s.setInstallationOptions)

  const cloudSyncState = async () => {
    if (!auth.isAuthenticated) return

    const { result: response, error } = await fetchWithAuth(CLOUD_SYNC_API_URL, {
      method: 'POST',
      body: JSON.stringify({ machinesSeen } satisfies SyncServerStateInput),
      timeout: 7000
    })

    if (error || !response || !response.ok) {
      console.error('Cloud sync error:', error?.message)
      return
    }

    const { error: convertToJSONError, result } = await mightFail(response.json())

    if (convertToJSONError) {
      // TODO: handle error
      console.log('invalid json', convertToJSONError.message)
      return false
    }

    const parsedResult = SyncServerStateOutputSchema.safeParse(result)

    if (!parsedResult.success) {
      console.error('Invalid response schema:', parsedResult.error.message)
      return
    }

    setInstallationOptions(parsedResult.data.installationOptions)
    setCloudLastSync(parsedResult.data.updatedOn)

    toast.success(t('notification.clould-sync-message'), {
      position: 'top-center',
      duration: 2000
    })
  }

  return cloudSyncState
}
