import { db } from '@/db/db'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Card, CardHeader, CardTitle, CardContent } from '@ui/components/ui/card'
import { cn } from '@ui/utils'
import { useLiveQuery } from 'dexie-react-hooks'
import { CheckIcon, CircleAlertIcon, Loader2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

type Mode = 'initializing' | 'offline' | 'not-logged-in' | 'syncing' | 'up-to-date'

export const RenderMessage = ({
  mode,
  installationsPendingSyncCount
}: {
  mode: Mode
  installationsPendingSyncCount: number
}) => {
  const { t } = useTranslation()
  // console.log('installationSyncCount', installationsPendingSyncCount)

  switch (mode) {
    case 'initializing':
      return (
        <>
          <br />
        </>
      )
    case 'offline': {
      return (
        <>
          <CircleAlertIcon className={cn('w-5')} />
          {t('home.cloud-sync-card.state-offline')}
        </>
      )
    }
    case 'not-logged-in': {
      return (
        <>
          <CircleAlertIcon className={cn('w-5')} />
          {t('home.cloud-sync-card.state-not-logged-in')}
        </>
      )
    }
    case 'syncing': {
      return (
        <>
          <Loader2Icon className="mr-1 h-5 w-5 animate-spin" />
          {t('home.cloud-sync-card.state-syncing', {
            count: installationsPendingSyncCount
          })}
        </>
      )
    }
    case 'up-to-date': {
      return (
        <>
          <>
            <CheckIcon strokeWidth={3} className="w-4 h-4" />
            {t('home.cloud-sync-card.state-up-to-date')}
          </>
        </>
      )
    }
  }
}

export const SyncStatus = () => {
  const navigate = useNavigate()
  const auth = useDataStoreLocalStorage(s => s.auth)
  const { t } = useTranslation()
  const installationsInDb = useLiveQuery(async () => {
    return await db.installation.toArray()
  })

  const isOnline = useDataStore(s => s.isOnline)

  const installationsInDbWithoutDuplicates = installationsInDb
    ? Array.from(
        new Map(
          installationsInDb.map(item => [item.machineId + item.installationTimestamp, item])
        ).values()
      )
    : []

  const installationsPendingSyncCount = installationsInDbWithoutDuplicates.filter(
    i => !i.shouldDelete
  ).length

  const mode = (
    !isOnline
      ? 'offline'
      : !auth?.isAuthenticated
        ? 'not-logged-in'
        : installationsPendingSyncCount > 0
          ? 'syncing'
          : 'up-to-date'
  ) satisfies Mode

  const isRedCard = !isOnline || !auth.isAuthenticated

  const handleOnClick = () => {
    if (mode === 'not-logged-in') {
      navigate('/?login')
    }

    // TODO: Add sync
  }

  return (
    <Card
      className={cn(
        'p-2 bg-zinc-900/70 rounded-2xl select-none',
        isRedCard && 'border-red-900 bg-[#241717b3]'
      )}
      onClick={handleOnClick}
    >
      <CardHeader className="pb-0">
        <CardTitle className="flex items-center gap-4">{t('home.cloud-sync-card.title')}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col">
        <div className="flex gap-2 items-center">
          <RenderMessage
            mode={mode}
            installationsPendingSyncCount={installationsPendingSyncCount}
          />
        </div>
      </CardContent>
    </Card>
  )
}
